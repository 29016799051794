<template>
    <div>
        <v-toolbar flat>
            <v-toolbar-title>{{ $t('src\\views\\quizes\\detail.2086') }}</v-toolbar-title>
        </v-toolbar>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.7485') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.createdAt | moment('DD.MM.YYYY HH:mm') }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.1912') }}</v-col>
            <v-col cols="12" sm="8">
                <template v-if="entity.blogger">
                    {{ entity.blogger.social }} ({{ entity.blogger.phone }})
                </template>
            </v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.2795') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.age }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.8183') }}</v-col>
            <v-col cols="12" sm="8">{{ sexes[entity.sex] }} <template v-if="entity.sex === 'other'">({{ entity.sexOther }})</template></v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.4854') }}</v-col>
            <v-col cols="12" sm="8">{{ directions }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.9070') }}</v-col>
            <v-col cols="12" sm="8">{{ directionsAdditional }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.4215') }}</v-col>
            <v-col cols="12" sm="8">{{ months }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.372') }}</v-col>
            <v-col cols="12" sm="8">{{ durations }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.7048') }}</v-col>
            <v-col cols="12" sm="8">{{ activities }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.1048') }}</v-col>
            <v-col cols="12" sm="8">{{ comforts }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.1342') }}</v-col>
            <v-col cols="12" sm="8" v-html="priceFormated(entity.price, currency)"></v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.8390') }}</v-col>
            <v-col cols="12" sm="8">{{ tripsCount }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.454') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.userCountry }} {{ entity.userState }} {{ entity.userCity }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.7945') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.userProfession }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.8884') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.experience }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.1727') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.ideas }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.7977') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.userName }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.5921') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.userPhone }}</v-col>
        </v-row>
        <v-row class="mx-0">
            <v-col cols="12" sm="4">{{ $t('src\\views\\quizes\\detail.email') }}</v-col>
            <v-col cols="12" sm="8">{{ entity.userEmail }}</v-col>
        </v-row>

        <v-row
            align="center"
            justify="space-between"
            class="mx-0">
            <span/>
            <v-btn
                v-if="entity._id && user.adminAccess.quizes >= ACCESS.FULL"
                color="error"
                @click="remove">{{ $t('src\\views\\quizes\\detail.3064') }}</v-btn>
        </v-row>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { imageSrc, priceFormated } from '../../helpers';
    import { ACCESS } from '@/vars';

    export default {
        name: 'QuizDetail',
        components: {
        },
        data: () => ({
            ACCESS,
            imageSrc,
            priceFormated,
            valid: true,
            sexes: {
                'male': 'Мужской',
                'female': 'Женский',
                'other': 'Другой',
            }
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('quizes', ['entity', 'dictionaries']),
            currency() {
                return process.env.VUE_APP_DEFAULT_CURRENCY;
            },
            directions() {
                if(!this.dictionaries.directions || !this.entity.directions) {
                    return '';
                }
                const entities = this.entity.directions.map(item => item.toString());
                let items = this.dictionaries.directions.filter(item => entities.indexOf(item.code.toString()) >= 0);
                return items.map(item => item.title).join(', ');
            },
            directionsAdditional() {
                if(!this.dictionaries.directionsAdditional || !this.entity.directionsAdditional) {
                    return '';
                }
                const entities = this.entity.directionsAdditional.map(item => item.toString());
                let items = this.dictionaries.directionsAdditional.filter(item => entities.indexOf(item.code.toString()) >= 0);
                return items.map(item => item.title).join(', ');
            },
            months() {
                if(!this.dictionaries.months || !this.entity.months) {
                    return '';
                }
                const entities = this.entity.months.map(item => item.toString());
                let items = this.dictionaries.months.filter(item => entities.indexOf(item.code.toString()) >= 0);
                return items.map(item => item.title).join(', ');
            },
            durations() {
                if(!this.dictionaries.durations || !this.entity.durations) {
                    return '';
                }
                const entities = this.entity.durations.map(item => item.toString());
                let items = this.dictionaries.durations.filter(item => entities.indexOf(item.code.toString()) >= 0);
                return items.map(item => item.title).join(', ');
            },
            activities() {
                if(!this.dictionaries.activities || !this.entity.activities) {
                    return '';
                }
                const entities = this.entity.activities.map(item => item.toString());
                let items = this.dictionaries.activities.filter(item => entities.indexOf(item.code.toString()) >= 0);
                return items.map(item => item.title).join(', ');
            },
            comforts() {
                if(!this.dictionaries.comforts || !this.entity.comforts) {
                    return '';
                }
                const entities = this.entity.comforts.map(item => item.toString());
                let items = this.dictionaries.comforts.filter(item => entities.indexOf(item.code.toString()) >= 0);
                return items.map(item => item.title).join(', ');
            },
            tripsCount() {
                if(!this.dictionaries.tripsCount || !this.entity.tripsCount) {
                    return '';
                }
                let item = this.dictionaries.tripsCount.find(item => this.entity.tripsCount.toString() === item.code.toString());
                return item.title;
            },
        },
        async mounted() {
            await store.dispatch('quizes/dictionaries');
            if(this.$route.params.id) {
                await store.dispatch('quizes/get', { id: this.$route.params.id });
            } else {
                await this.$router.push({ name: 'quiz-list' });
            }
        },
        methods: {
            async remove() {
                await store.dispatch('quizes/delete', { id: this.entity._id });
                await this.$router.push({ name: 'quiz-list' });
            }
        }
    }
</script>

<style lang="scss">
    .v-image {
        width: 300px;
        margin: 0 auto 20px;
        border-radius: 6px;
    }
</style>
